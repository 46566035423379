import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import { Control } from 'leaflet';
(function (factory) {
  if (typeof define === "function" && define.amd) {
    define(["leaflet"], factory);
  } else if (typeof module !== "undefined") {
    module.exports = factory(require("leaflet"));
  } else {
    if (typeof window.L === "undefined") {
      throw new Error("Leaflet must be loaded first");
    }
    factory(window.L);
  }
})(function (L) {
  L.Control.Fullscreen = L.Control.extend({
    options: {
      position: "topleft",
      title: {
        false: "View fullscreen",
        true: "Exit fullscreen"
      }
    },
    onAdd: function (map) {
      var container = L.DomUtil.create("div", "leaflet-control-fullscreen leaflet-bar leaflet-control");
      this.link = L.DomUtil.create("a", "leaflet-control-fullscreen-button leaflet-bar-part", container);
      this.link.href = "#";
      this.link.setAttribute("role", "button");
      this.icon = L.DomUtil.create("span", "leaflet-control-fullscreen-icon", this.link);
      this._map = map;
      this._map.on("fullscreenchange", this._toggleTitle, this);
      this._toggleTitle();
      L.DomEvent.on(this.link, "click", this._click, this);
      return container;
    },
    _click: function (e) {
      L.DomEvent.stopPropagation(e);
      L.DomEvent.preventDefault(e);
      this._map.toggleFullscreen(this.options);
    },
    _toggleTitle: function () {
      this.link.title = this.options.title[this._map.isFullscreen()];
    }
  });
  L.Map.include({
    isFullscreen: function () {
      return this._isFullscreen || false;
    },
    toggleFullscreen: function (options) {
      var container = this.getContainer();
      if (this.isFullscreen()) {
        if (options && options.pseudoFullscreen) {
          this._disablePseudoFullscreen(container);
        } else if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else {
          this._disablePseudoFullscreen(container);
        }
      } else {
        if (options && options.pseudoFullscreen) {
          this._enablePseudoFullscreen(container);
        } else if (container.requestFullscreen) {
          container.requestFullscreen();
        } else if (container.mozRequestFullScreen) {
          container.mozRequestFullScreen();
        } else if (container.webkitRequestFullscreen) {
          container.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (container.msRequestFullscreen) {
          container.msRequestFullscreen();
        } else {
          this._enablePseudoFullscreen(container);
        }
      }
    },
    _enablePseudoFullscreen: function (container) {
      L.DomUtil.addClass(container, "leaflet-pseudo-fullscreen");
      this._setFullscreen(true);
      this.fire("fullscreenchange");
    },
    _disablePseudoFullscreen: function (container) {
      L.DomUtil.removeClass(container, "leaflet-pseudo-fullscreen");
      this._setFullscreen(false);
      this.fire("fullscreenchange");
    },
    _setFullscreen: function (fullscreen) {
      this._isFullscreen = fullscreen;
      var container = this.getContainer();
      if (fullscreen) {
        L.DomUtil.addClass(container, "leaflet-fullscreen-on");
      } else {
        L.DomUtil.removeClass(container, "leaflet-fullscreen-on");
      }
      this.invalidateSize();
    },
    _onFullscreenChange: function (e) {
      var fullscreenElement = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
      if (fullscreenElement === this.getContainer() && !this._isFullscreen) {
        this._setFullscreen(true);
        this.fire("fullscreenchange");
      } else if (fullscreenElement !== this.getContainer() && this._isFullscreen) {
        this._setFullscreen(false);
        this.fire("fullscreenchange");
      }
    }
  });
  L.Map.mergeOptions({
    fullscreenControl: false
  });
  L.Map.addInitHook(function () {
    if (this.options.fullscreenControl) {
      this.fullscreenControl = new L.Control.Fullscreen(this.options.fullscreenControl);
      this.addControl(this.fullscreenControl);
    }
    var fullscreenchange;
    if ("onfullscreenchange" in document) {
      fullscreenchange = "fullscreenchange";
    } else if ("onmozfullscreenchange" in document) {
      fullscreenchange = "mozfullscreenchange";
    } else if ("onwebkitfullscreenchange" in document) {
      fullscreenchange = "webkitfullscreenchange";
    } else if ("onmsfullscreenchange" in document) {
      fullscreenchange = "MSFullscreenChange";
    }
    if (fullscreenchange) {
      var onFullscreenChange = L.bind(this._onFullscreenChange, this);
      this.whenReady(function () {
        L.DomEvent.on(document, fullscreenchange, onFullscreenChange);
      });
      this.on("unload", function () {
        L.DomEvent.off(document, fullscreenchange, onFullscreenChange);
      });
    }
  });
  L.control.fullscreen = function (options) {
    return new L.Control.Fullscreen(options);
  };
});

/// <reference types='@runette/leaflet-fullscreen'/>
class FullscreenControlComponent {
  constructor() {
    this._options = {};
  }
  set options(opt) {
    this._options = opt;
    this.control = new Control.Fullscreen(this._options);
    if (this._map) {
      this._map?.addControl(this.control);
      this._map?.on('enterFullscreen', () => this._map?.invalidateSize());
      this._map?.on('exitFullscreen', () => this._map?.invalidateSize());
    }
  }
  ngOnInit() {}
  ngOnDestroy() {
    if (this.control) {
      this._map?.removeControl(this.control);
      this._map?.off('enterFullscreen');
      this._map?.off('exitFullscreen');
    }
  }
  set map(map) {
    if (map) {
      this._map = map;
      if (this.control) {
        map.addControl(this.control);
        map.on('enterFullscreen', () => map.invalidateSize());
        map.on('exitFullscreen', () => map.invalidateSize());
      }
    }
    ;
  }
  get map() {
    return this._map;
  }
}
FullscreenControlComponent.ɵfac = function FullscreenControlComponent_Factory(ɵt) {
  return new (ɵt || FullscreenControlComponent)();
};
FullscreenControlComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FullscreenControlComponent,
  selectors: [["leaflet-fullscreen-control"]],
  inputs: {
    options: "options",
    map: "map"
  },
  decls: 0,
  vars: 0,
  template: function FullscreenControlComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FullscreenControlComponent, [{
    type: Component,
    args: [{
      selector: 'leaflet-fullscreen-control',
      template: ''
    }]
  }], function () {
    return [];
  }, {
    options: [{
      type: Input
    }],
    map: [{
      type: Input
    }]
  });
})();
;
class NgxLeafletFullscreenModule {}
NgxLeafletFullscreenModule.ɵfac = function NgxLeafletFullscreenModule_Factory(ɵt) {
  return new (ɵt || NgxLeafletFullscreenModule)();
};
NgxLeafletFullscreenModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxLeafletFullscreenModule
});
NgxLeafletFullscreenModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxLeafletFullscreenModule, [{
    type: NgModule,
    args: [{
      declarations: [FullscreenControlComponent],
      imports: [],
      exports: [FullscreenControlComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { FullscreenControlComponent, NgxLeafletFullscreenModule };
