<section class="user-add-page">
    <mat-card class="card-default-style">
        <mat-card-title class="text-capitalize fs-20">
            <div class="justify-content-between align-items-center">
                <mat-tab-group [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="onTabChanged($event);">
                    <div class="fs-25">
                        <mat-tab label="Users" *ngxPermissionsOnly="[permission.VIEW_CUSTOMUSER]">
                            <!-- <app-user-card></app-user-card> -->
                            <ng-container *ngIf="componentEnable">
                                <app-users-list [searchtext]="searchKeyword"></app-users-list>
                            </ng-container>
                        </mat-tab>
                        <mat-tab label="Approver List" *ngxPermissionsOnly="[permission.CAN_VIEW_PRIVATE_VALUER]">
                            <ng-container *ngIf="componentEnable && selectedTabIndex == 1">
                                <app-users-list [searchtext]="searchKeyword" [approvelist]="approveList"></app-users-list>
                            </ng-container>
                        </mat-tab>
                        <mat-tab label="Roles" *ngxPermissionsOnly="[permission.VIEW_CUSTOMGROUP]">
                            <!-- <ng-container *ngIf="selectedTabIndex == 1"> -->
                                <ng-container> 
                                    <app-roles-list
                                [searchtext]="searchKeyword"></app-roles-list>
                            </ng-container>
                        </mat-tab>
                        <mat-tab label="User Group" *ngxPermissionsOnly="[permission.VIEW_ENTITY]">
                            <ng-container>
                                <app-entity-list [searchtext]="searchKeyword"></app-entity-list>
                            </ng-container>
                        </mat-tab>
                    </div>
                </mat-tab-group>
                <!-- <div class="ml-auto search-form">
                    <custom-input inputClass="removeInputBottomPadding" [inputFormGroup]="searchForm"
                        [inputPlaceholder]="staticText?.common?.search_keyword" inputFormControlName="keyword"
                        inputSuffixIcon="search" [enableClear]="true">
                    </custom-input>
                </div> -->
            </div>
        </mat-card-title>
    </mat-card>
</section>