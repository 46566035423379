import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { routePath } from '../services/utils/constants';
import { CommonService } from '../services/common/common.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard {
  constructor(private _authService: AuthService, private _router: Router, private _common: CommonService) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const requiredPermission = route.data['requiredPermission'];
    if (this._authService.isLoggedIn() && this._authService.hasPermission(requiredPermission)) {
      return true;
    } else {
      this._router.navigate([routePath.VIEW_REDIRECT_403_PATH])
      return false;
    }
  }



  canLoad(): boolean {
    if (this._authService.isLoggedIn())
      return true;
    else {
      this._router.navigate([routePath?.AUTH_PATH])
      return false
    }
  }
}