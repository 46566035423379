import { Injectable } from '@angular/core';
import { Subject, map, takeUntil } from 'rxjs';
import { HttpService } from './http/http.service';
import { DatePipe } from '@angular/common';
import { ApiMethod, Endpoints } from './utils/constants';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private destroy$ = new Subject<void>();
  valuationList: any;
  assigneeList: any;
  userList: any;
  user: any;
  transactionList: any;
  entityList: any;
  constructor(private _http: HttpService, private datePipe: DatePipe) { }

  onDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  

  getPermissionList() {
   return this._http.requestCall(Endpoints.GET_KEYCLOCK_PERMISSION_LIST, ApiMethod.GET)
  }
  getRoleList(role_id:any) {
    // if(!role_id)
    //     return [];
    let endpoint: any = Endpoints.GET_ROLES_LIST + role_id;
   return this._http.requestCall(endpoint, ApiMethod.GET)

  }

   getEntity(): void {
    let endpoint: any = Endpoints.GET_DROPDOWN_ENTITY;
   return this._http
      .requestCall(endpoint, ApiMethod.GET)
     
  }
 
  
}
