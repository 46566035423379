<div class="d-flex flex-column">
    <!-- <div class="custom-table-filter"> -->
        <div class="custom-table-container ">
            <div class="p-3 pt-1 pb-2">
                <div class="row row-cols-lg-4 row-cols-sm-1">
                    <div class="col-4" *ngFor="let item of userRolesData ">
                        <div class="status_box" >
                            <div class="d-flex flex-row">
                                <span class="box-colored" [class]="item?.className" >
                                    {{item?.count}}
                                </span>
                                <div class="d-flex align-items-center ">
                                    <label class="status_label ">{{item?.display_name}}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
<!-- 
    </div> -->
</div>
    
       