import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserManagementComponent } from './user-management.component';
import { UserViewEditComponent } from './users/user-view-edit/user-view-edit.component';
import { RoleMgmtComponent } from './roles/role-mgmt/role-mgmt.component';
import { AuthGuard } from 'src/app/core/guard/auth.guard';
import { UsersListComponent } from './users/users-list/users-list.component';
import { USERMANAGEMENT } from 'src/app/core/services/utils/constants';
import { RolesListComponent } from './roles/roles-list/roles-list.component';
import { EntityListComponent } from './entity/entity-list/entity-list.component';


const routes: Routes = [
  {
    path: 'users',
    canActivate: [AuthGuard],
    data: {
      mode: USERMANAGEMENT.USER,
      breadcrumb: 'Users',
      page_title: 'User'
    },
    component: UsersListComponent
  },  
  {
    path: 'user-type',
    canActivate: [AuthGuard],
    data: {
      mode: USERMANAGEMENT.ENTITY,
      breadcrumb: 'User Type',
      page_title: 'User Type'
    },
    component: EntityListComponent
  },      
  // {
  //   path: 'list/:id',
  //   canActivate: [AuthGuard],
  //   data: {
  //     breadcrumb: 'Users', page_title: 'User Management'
  //   },
  //   component: UserManagementComponent
  // },
  {
    path: 'users/view/:id',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'User', page_title: 'View User',
      navigation: {
        router: "/users/list",
        icon: "arrow_navigate_back",
      },
    },
    component: UserViewEditComponent
  },
  {
    path: 'users/edit/:id',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'User', page_title: 'Edit User',
      navigation: {
        router: "/users/list",
        icon: "arrow_navigate_back",
      },
    },
    component: UserViewEditComponent
  },
  {
    path: 'users/add',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'User', page_title: 'Add New User',
      navigation: {
        router: "/users/list",
        icon: "arrow_navigate_back",
      },
    },
    component: UserViewEditComponent
  },
  {
    path: 'roles',
    canActivate: [AuthGuard],
    data: {
      mode: USERMANAGEMENT.ROLE,
      breadcrumb: 'Roles',
      page_title: 'Role'
    },
    component: RolesListComponent
  },
  {
    path: 'role/edit/:id',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Edit Role', page_title: 'Edit Role',
      navigation: {
        router: "/users/list/1",
        icon: "arrow_navigate_back",
      },
    },
    component: RoleMgmtComponent
  },
  {
    path: 'role/view/:id',
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'View Role', page_title: 'View Role',
      navigation: {
        router: "/users/list/1",
        icon: "arrow_navigate_back",
      },
    },
    component: RoleMgmtComponent
  },
  {
    path: 'role/add',
    canActivate: [AuthGuard],
    data: {
      page_title: 'Add Role',
      navigation: {
        router: "/users/list/1",
        icon: "arrow_navigate_back",
      },
    },
    component: RoleMgmtComponent
  },
  {
    path: '',
    redirectTo: 'users',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserManagementRoutingModule { }
