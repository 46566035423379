import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserManagementRoutingModule } from './user-management-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { NgxLeafletFullscreenModule } from '@runette/ngx-leaflet-fullscreen';
import { UserManagementComponent } from './user-management.component';
import { UsersListComponent } from './users/users-list/users-list.component';
import { UserViewEditComponent } from './users/user-view-edit/user-view-edit.component';
import { EntityListComponent } from './entity/entity-list/entity-list.component';
import { EntityAddComponent } from './entity/entity-add/entity-add.component';
import { RolesListComponent } from './roles/roles-list/roles-list.component';
import { RoleMgmtComponent } from './roles/role-mgmt/role-mgmt.component';
import { UserCardComponent } from './users/user-card/user-card.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LocationFormComponent } from 'src/app/shared/components/location-form/location-form.component';
// import { SignUpComponent } from '../auth/sign-up/sign-up.component';


@NgModule({
  declarations: [
    UserManagementComponent,
    UserCardComponent,
    UsersListComponent,
    UserViewEditComponent,
    EntityListComponent,
    EntityAddComponent,
    RolesListComponent,
    RoleMgmtComponent,
  ],
  imports: [
    CommonModule,
    UserManagementRoutingModule,
    SharedModule,
    LeafletModule,
    LeafletDrawModule,
    NgxLeafletFullscreenModule,
    NgxPermissionsModule,
    LocationFormComponent
  ],
 exports: [
   UserViewEditComponent
  ]
  

})
export class UserManagementModule { }
