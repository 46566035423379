import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppState } from './master.state';

export const getMasterSelector = createFeatureSelector<AppState>('master');

export const getRegionList = createSelector(getMasterSelector, (state: any) => {
    return state.region.list;
})

export const getRegionAPIProgressStatus = createSelector(getMasterSelector, (state: any) => {
    return state.region.isInProgress;
})

export const getRegionAPICompletedStatus = createSelector(getMasterSelector, (state: any) => {
    return state.region.isLoaded;
})

export const getMzoList = createSelector(getMasterSelector, (state: any) => {
    return state.mzo.list;
})

export const getMzoAPIProgressStatus = createSelector(getMasterSelector, (state: any) => {
    return state.mzo.isInProgress;
})

export const getMzonAPICompletedStatus = createSelector(getMasterSelector, (state: any) => {
    return state.mzo.isLoaded;
})

export const getDistrictList = createSelector(getMasterSelector, (state: any) => {
    return state.district.list;
})

export const getDistrictAPIProgressStatus = createSelector(getMasterSelector, (state: any) => {
    return state.district.isInProgress;
})

export const getDistrictAPICompletedStatus = createSelector(getMasterSelector, (state: any) => {
    return state.district.isLoaded;
})

export const getCountyList = createSelector(getMasterSelector, (state: any) => {
    return state.county.list;
})

export const getCountyAPIProgressStatus = createSelector(getMasterSelector, (state: any) => {
    return state.county.isInProgress;
})

export const getCountyAPICompletedStatus = createSelector(getMasterSelector, (state: any) => {
    return state.county.isLoaded;
})

export const getSubcountyList = createSelector(getMasterSelector, (state: any) => {
    return state.sub_county.list;
})

export const getSubcountyAPIProgressStatus = createSelector(getMasterSelector, (state: any) => {
    return state.sub_county.isInProgress;
})

export const getSubcountyAPICompletedStatus = createSelector(getMasterSelector, (state: any) => {
    return state.sub_county.isLoaded;
})

export const getParishList = createSelector(getMasterSelector, (state: any) => {
    return state.parish.list;
})

export const getParishAPIProgressStatus = createSelector(getMasterSelector, (state: any) => {
    return state.parish.isInProgress;
})

export const getParishAPICompletedStatus = createSelector(getMasterSelector, (state: any) => {
    return state.parish.isLoaded;
})

export const getVillageList = createSelector(getMasterSelector, (state: any) => {
    return state.village.list;
})

export const getVillageAPIProgressStatus = createSelector(getMasterSelector, (state: any) => {
    return state.village.isInProgress;
})

export const getVillageAPICompletedStatus = createSelector(getMasterSelector, (state: any) => {
    return state.village.isLoaded;
})

export const getBasisList = createSelector(getMasterSelector, (state: any) => {
    return state.basis.list;
})

export const getBasisAPIProgressStatus = createSelector(getMasterSelector, (state: any) => {
    return state.basis.isInProgress;
})

export const getBasisAPICompletedStatus = createSelector(getMasterSelector, (state: any) => {
    return state.basis.isLoaded;
})

export const getTenureList = createSelector(getMasterSelector, (state: any) => {
    return state.tenure.list;
})

export const getTenureAPIProgressStatus = createSelector(getMasterSelector, (state: any) => {
    return state.tenure.isInProgress;
})

export const getTenureAPICompletedStatus = createSelector(getMasterSelector, (state: any) => {
    return state.tenure.isLoaded;
})

export const getDocumentCategoryList = createSelector(getMasterSelector, (state: any) => {
    return state.documentCategory.list;
})

export const getDocumentCategoryAPIProgressStatus = createSelector(getMasterSelector, (state: any) => {
    return state.documentCategory.isInProgress;
})

export const getDocumentCategoryAPICompletedStatus = createSelector(getMasterSelector, (state: any) => {
    return state.documentCategory.isLoaded;
})

export const getValuationTypeList = createSelector(getMasterSelector, (state: any) => {
    return state.valuationType.list;
})

export const getValuationTypeAPIProgressStatus = createSelector(getMasterSelector, (state: any) => {
    return state.valuationType.isInProgress;
})

export const getValuationTypeAPICompletedStatus = createSelector(getMasterSelector, (state: any) => {
    return state.valuationType.isLoaded;
})

export const getPropertyTypeList = createSelector(getMasterSelector, (state: any) => {
    return state.propertyType.list;
})

export const getPropertyTypeAPIProgressStatus = createSelector(getMasterSelector, (state: any) => {
    return state.propertyType.isInProgress;
})

export const getPropertyTypeAPICompletedStatus = createSelector(getMasterSelector, (state: any) => {
    return state.propertyType.isLoaded;
})