import { Injectable } from '@angular/core';
import { Subject, map, takeUntil } from 'rxjs';
import { HttpService } from './http/http.service';
import { DatePipe } from '@angular/common';
import { ApiMethod, Endpoints } from './utils/constants';
import { LodashService } from './lodash/lodash.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private destroy$ = new Subject<void>();
  valuationList: any;
  assigneeList: any;
  userList: any;
  user: any;
  transactionList: any;
  entityList: any;

  locationJson: any = [];

  constructor(private _http: HttpService, private datePipe: DatePipe, private lodash: LodashService) { }

  onDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }


  getUserList(userId: any) {
    let endpoint: any = Endpoints.GET_USER_LISTS + userId + '/';
    return this._http.requestCall(endpoint, ApiMethod.GET)
  }

  getLocation(parent?: any, order?: number) {
    let endpoint: any = '';
    if (parent)
      endpoint = `${Endpoints.GET_LOCATION_DROPDOWN}?parent=${parent}`;
    else
      endpoint = Endpoints.GET_LOCATION_DROPDOWN;
    return this._http.requestCall(endpoint, ApiMethod.GET)
      .pipe(map((response: any) => {
        const kebabCase = this.lodash.kebabCase(Object.keys(response?.data)?.[0]);
        const name = Object.keys(response?.data)?.toString()?.replaceAll(',', '/');
        const alreadyExist = this.locationJson.some((x: any) => x.controlName == kebabCase);

        //For Group Select...............
        let groupDetails = []; 
        for (let index = 0; index < Object.keys(response?.data).length; index++) {
          const element = Object.keys(response?.data)[index];
          let obj:any = {};
          obj.display_name = element;
          obj.group = response?.data[element];
          groupDetails.push(obj)
        }

        //...............For Group Select
        if (name) {
          if (alreadyExist) {
            this.locationJson.find((x: any) => x.controlName == kebabCase).data = response?.data?.[Object.keys(response?.data)?.[0]];
            this.locationJson.find((x: any) => x.controlName == kebabCase).group = 1 < Object.keys(response?.data).length;
            this.locationJson.find((x: any) => x.controlName == kebabCase).groupData = groupDetails;
          } else {
            this.locationJson.push({
              index: order,
              controlName: kebabCase,
              name: name,
              data: response?.data?.[Object.keys(response?.data)?.[0]],
              group : 1 < Object.keys(response?.data).length,
              groupData: groupDetails
            })
          }
        }
        this.locationJson = this.locationJson?.sort((a: any, b: any) => a.index - b.index);
        return response;
      }), takeUntil(this.destroy$))
  }

}
