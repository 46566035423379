import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { ApiMethod, Endpoints } from 'src/app/core/services/utils/constants';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {

  userRolesData: any = [
    // {
    //   count: "01",
    //   name: "Land refrence 01",
    //   className: "Card-One"
    // },
    // {
    //   count: "02",
    //   name: "Land refrence 02",
    //   className: "Card-Two"
    // },
    // {
    //   count: "03",
    //   name: "Land refrence 03",
    //   className: "Card-Three"
    // },
    // {
    //   count: "04",
    //   name: "Land refrence 04",
    //   className: "Card-Four"
    // },
    // {
    //   count: "05",
    //   name: "Land refrence 01",
    //   className: "Card-Five"
    // },
    // {
    //   count: "06",
    //   name: "Land refrence 02",
    //   document_type: "LAVMIS_34",
    //   className: "Card-Six"
    // },
    // {
    //   count: "07",
    //   name: "Land refrence 03",
    //   className: "Card-Seven"
    // },
    // {
    //   count: "08",
    //   name: "Land refrence 04",
    //   className: "Card-Eight"
    // },
   
  ]

  constructor(
    private _http: HttpService,
    private _lodash: LodashService) {

   }

  ngOnInit(): void {
    this.getUserCardCountDetails();
  }

  getBackgroundClass(index:number) {
    let bgColorsArray = ["Card-One", 'Card-Two', 'Card-Three', 'Card-Four', 'Card-Five', 'Card-Six', 'Card-Seven', 'Card-Eight'];
    return bgColorsArray[index % 8];
  }

  addClassNameForUserData(data:any) {
    data.forEach((o:any, i:number) => {
      o['className'] = this.getBackgroundClass(i);
      o['count'] = Number(this._lodash.getData(o, 'count', 0)) < 10 ? '0'+this._lodash.getData(o, 'count', 0): this._lodash.getData(o, 'count', 0);
    })
    this.userRolesData = data;
  }

  getUserCardCountDetails() {
    let endpoint: any = Endpoints.GET_USER_LISTS+'users_count/';
    this._http.requestCall(endpoint, ApiMethod.GET).subscribe((response: any) => {
      console.log('getUserCardCountDetails: ', response)
      this.addClassNameForUserData(response);
    });
  }

}
