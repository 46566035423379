<section class="entity-add-edit-page">
  <div class="entity-card-container d-flex flex-column">
    <!-- header part -->
    <div class="entity-header d-flex flex-column justify-content-center">
      <div class="container entity-title">
        <div class="d-flex flex-row justify-content-between align-items-center">

          <label class="text-black fw-600 fs-20"
            [innerHtml]="
            majorDialogData?.create_button ? staticText?.user_management?.entity?.add?.add_page_title : (majorDialogData?.data?.action?.mode === 'edit'&& majorDialogData?.data?.action?.icon != 'view_action' ? staticText?.user_management?.entity?.edit?.edit_page_title : staticText?.user_management?.entity?.view?.view_page_title)">
          </label>

          <img class="cursor-pointer" src="./assets/images/common/icons/close_black.svg" alt="close"
            (click)="close()" />
        </div>
      </div>
    </div>
    <!-- body part -->
    <mat-card-content>
      <div class="entity-body">
        <div class="d-flex flex-column px-3"
        [ngClass]="{'py-0': majorDialogData?.data?.action?.icon == 'view_action', 'py-4': majorDialogData?.data?.action?.icon !== 'view_action'}"
         >
       
          <form [formGroup]="entityMgmtForm">
            <div class="d-flex flex-column row mx-auto">
              <div class=""
                *ngIf="majorDialogData?.data?.action?.mode == 'edit'&& majorDialogData?.data?.action?.icon != 'view_action' || majorDialogData?.create_button">
                <div class="col">
                  <custom-input [inputFormGroup]="entityMgmtForm" [inputAppearance]=""
                    inputFormControlName="entity_name" [inputRequiredStatus]="true" [inputLabel]="
                        staticText?.user_management?.entity?.add?.add_input_1
                      " [inputPlaceholderLabel]="
                        staticText?.user_management?.entity?.add?.add_input_1
                      " [inputErrorLabel]="
                        entityMgmtForm.get('entity_name').errors
                          ?.validation_message
                      ">
                  </custom-input>
                </div>
                <!-- <div class="col">
                  <custom-input [inputFormGroup]="entityMgmtForm" [inputAppearance]=""
                    inputFormControlName="description" [inputRequiredStatus]="false" [inputLabel]="
                        staticText?.user_management?.entity?.add?.add_input_3
                      " [inputPlaceholderLabel]="
                        staticText?.user_management?.entity?.add?.add_input_3
                      " [inputErrorLabel]="
                        entityMgmtForm.get('description').errors
                          ?.validation_message
                      ">
                  </custom-input>
                </div> -->

                <div class="status-section mb-2 mt-5" *ngIf="majorDialogData?.data?.action?.mode == 'edit' || majorDialogData?.create_button">
                  <div>
                    <label class="status-label" [innerHtml]="
                                staticText?.user_management?.entity?.edit?.edit_input_3
                              "></label>
                  </div>

                  <div class="active-inactive py-1">
                    <mat-label (click)="updateStatus(true)"
                      [ngClass]="{
                      'status-active': this.entityMgmtForm.value.status
                    }" class="px-3 cursor-pointer" [innerHtml]="staticText?.common?.active"></mat-label>
                    <mat-label (click)="updateStatus(false)"
                      [ngClass]="{
                          'status-in-active': !this.entityMgmtForm.value.status
                        }" class=" px-3 cursor-pointer" [innerHtml]="staticText?.common?.inactive"></mat-label>
                    <mat-slide-toggle formControlName="status" style="display: none;"></mat-slide-toggle>
                  </div>

                </div>
              </div>
              <div class="row row-cols-2"
                *ngIf="majorDialogData?.data?.action?.mode == 'view' || majorDialogData?.data?.action?.icon == 'view_action'">
                <div class="col py-3">
                  <label class="color_label  mb-2"
                    [innerHtml]="staticText?.user_management?.entity?.view?.view_page_label_1"></label>
                  <p class="label_txt_view">{{majorDialogData?.data?.Data?.entity_name}}</p>
                </div>
                 <div class="col py-3">
                  <label class="color_label mb-2"
                    [innerHtml]="staticText?.user_management?.entity?.view?.view_page_label_6"></label>
                    
                  <p class="fs-16 transaction_number" [ngClass]="{'active_sts': majorDialogData?.data?.Data?.is_active  == true,'inactive_sts':majorDialogData?.data?.Data?.is_active  == false}">
                    {{majorDialogData?.data?.Data?.is_active ? "Active" : "Inactive" }}</p>
                </div>
                <!-- <div class="col py-3">
                  <label class="color-primary fs-14 mb-2"
                    [innerHtml]="staticText?.user_management?.entity?.view?.view_page_label_5"></label>
                  <p class="color-darkgrey fw-600 fs-14 transaction_number">
                    {{majorDialogData?.data?.Data?.description}}</p>
                </div> -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </mat-card-content>

    <!-- footer part -->
    <div class="major-footer">
      
      <div class="major-modal-actions  cursor-pointer px-3 py-3  fs-16">
       
        <div class="major-modal-close-button px-2 d-flex justify-content-end" [ngClass]="{'w-100': majorDialogData?.data?.action?.icon == 'view_action', 'w-unset':majorDialogData?.data?.action?.icon !== 'view_action'  }">
          <button class="btn_default_user" mat-stroked-button 
              (click)="close()">{{majorDialogData?.data?.action?.icon == 'view_action' ? staticText?.common?.close_button_view : staticText?.common?.cancel_button }}</button>
        </div>
        <div class="major-modal-event-button px-2">
          <button
            *ngIf="majorDialogData?.data?.action?.mode == 'edit'&& majorDialogData?.data?.action?.icon != 'view_action' || majorDialogData?.create_button"
            mat-raised-button class="btn_login_user"
            (click)="addEditEntity(this.entityMgmtForm.value, this.entityMgmtForm)">
            <div>
              <span [innerHtml]="
                    majorDialogData?.create_button
                      ? staticText?.create_button || staticText?.user_management?.entity?.add?.add_page_button
                      : staticText?.common?.update_button || staticText?.user_management?.entity?.edit?.edit_page_button
                  " class=" "></span>
              <!-- <img src="/assets/images/common/icons/arrow_right_dark.svg" alt="button_icon" class="mx-2"> -->
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>