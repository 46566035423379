import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
// import { ErrorCodes, SuccessMessage, colorCodes, routePath } from 'src/app/core/services/utils/constants';
import { FormValidatorService } from 'src/app/shared/services/form-validator/form-validator.service';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { CommonService } from 'src/app/core/services/common/common.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import { ApiMethod, Endpoints, ErrorCodes, SuccessMessage, routePath } from 'src/app/core/services/utils/constants';
import { HttpService } from 'src/app/core/services/http/http.service';

@Component({
  selector: 'app-entity-add',
  templateUrl: './entity-add.component.html',
  styleUrls: ['./entity-add.component.scss']
})
export class EntityAddComponent implements OnInit {

  entityMgmtForm: FormGroup | any;
  staticText: any = (textConfiguration as any).default;
  entityList: any = [];
  isEditPage: boolean = false;
  isViewPage: boolean = true;

  constructor(public _createDialogRef: MatDialogRef<EntityAddComponent>,
    private _http: HttpService,
    private formBuilder: FormBuilder,
    private _formValidator: FormValidatorService,
    private _snackBar: SnackbarService,
    private _modal: ModalService,
    private _loader: LoaderService,
    protected _common: CommonService,
    public _routeService: RouterService,
    @Inject(MAT_DIALOG_DATA) public majorDialogData: any) { }

  ngOnInit(): void {
    this.initialDependencies();
  }

  initialDependencies(){
    this.createForm();
    this.patchEntityData();
  }

  createForm(){
    this.entityMgmtForm = this.formBuilder.group({
      entity_name: [
        '',
        [this._formValidator.requiredValidationCheck('User Group Name'), this._formValidator.minMaxLengthCheck(1,100)],
      ],
      description: [''],
      status: [true]
    })
    console.log(this.entityMgmtForm.value);
    
  }

  patchEntityData() {
    if (this.majorDialogData?.data?.action?.mode == 'edit') {
      this.isEditPage = true
    } 
    this.entityMgmtForm?.patchValue({
      entity_name: this.majorDialogData?.data?.Data?.entity_name || "",
      description: this.majorDialogData?.data?.Data?.description || "",
      status: this.majorDialogData?.data?.Data?.is_active ?? true
    });
  }

  addEditEntity(data:any, id:number){
    if (this.entityMgmtForm.invalid) {
      this.entityMgmtForm.markAllAsTouched();
      return;
    }
    this._loader.show();

    const payload = {
      "name": this.entityMgmtForm.value.entity_name,
      "display_name": this.entityMgmtForm.value.entity_name,
      "description": this.entityMgmtForm.value.description,
      "is_active": this.entityMgmtForm.value.status
    };
    
    const endpoint: any = this.majorDialogData?.create_button === 'Create'
      ? Endpoints.GET_ENTITY_LIST
      : `${Endpoints.GET_ENTITY_LIST}${this.majorDialogData?.data?.Data?.id}/`;

    const method = this.majorDialogData?.create_button === 'Create' ? ApiMethod.POST : ApiMethod.PUT;

    this._http.requestCall(endpoint, method, payload, { observe: 'response' }).subscribe(
      
      (getCreatedResponse: any) => {
        console.log(getCreatedResponse, "getCreatedResponse");
        
        if (this.majorDialogData?.create_button === 'Create') {
          if (getCreatedResponse?.status === ErrorCodes?.HTTP_201_CREATED) {
            this.entityMgmtForm.reset();
            this._modal.closeDialog();
            this._modal.openSucceedDialog({ data: {
              header:  `<div>New User Group Created!</div>`,
              msg:  ` <p class=" success-text"  [innerHTML]="successDialogData?.msg">
                      New user group has been successfully created.
                    </p>`,
                
                    
                    
                    


               
             


             
              

             
              
              paragraph: SuccessMessage.ENTITY_ADD_MSG } });
            this._routeService.navigatePages(routePath?.ENTITY_LIST_REDIRECT_PATH);
          }
        } else {
          if (getCreatedResponse?.status === ErrorCodes?.HTTP_200_SUCCESS) {
            this._routeService.navigatePages(routePath?.ENTITY_LIST_REDIRECT_PATH);
            this.entityMgmtForm.reset();
            this._modal.closeDialog();
            this._modal.openSucceedDialog({ data: {
              msg:  `<p class=" success-text"  [innerHTML]="successDialogData?.msg">
                        Changes of  <span class="user_name">${getCreatedResponse?.body?.data?.display_name
}</span> user group have been successfully updated.
                    </p>`,
              header: `<div>User Group Details Updated!</div>`,
              
              paragraph: SuccessMessage.ENTITY_UPDATE_MSG } });
          }
        }
        this._loader.hide();
      },
      (error: any) => {
        this._loader.hide();
      }
    );

  }

  close() {
    this._createDialogRef.close(false);
  }

  updateStatus(status:boolean){
    this.entityMgmtForm.get('status').setValue(status);
  }

}
