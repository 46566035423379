import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/core/services/common/common.service';
import { HttpService } from 'src/app/core/services/http/http.service';
import { LoaderService } from 'src/app/core/services/loader/loader.service';
import { RouterService } from 'src/app/core/services/router/router.service';
import { SnackbarService } from 'src/app/core/services/snackBar/snackbar.service';
import { ApiMethod, BaseURL, Endpoints, FORMAT, FailedMessage, KeyCloak, PagePermssions, StateManagement, SuccessMessage, colorCodes } from 'src/app/core/services/utils/constants';
import { ModalService } from 'src/app/shared/services/modal/modal.service';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { HttpClient } from '@angular/common/http';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service';
import { CustomTableComponent } from 'src/app/shared/components/custom-table/custom-table.component';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { Store, State } from '@ngrx/store';
import { addDataToMaster, removeDataFromMaster, setMasterAPIProgressStatus, setMasterList, updateDataToMaster } from 'src/app/state/master/master.action';
import { AppState } from 'src/app/state/master/master.state';
import { getDistrictList, getMzoList, getRegionList } from 'src/app/state/master/master.selector';
import { MasterService } from 'src/app/state/master/master.service';
import { CustomTableService } from 'src/app/shared/services/custom-table/custom-table.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomTableFilterComponent } from 'src/app/shared/components/custom-table/custom-table-filter/custom-table-filter.component';
import { ActivatedRoute } from '@angular/router';
import { UserViewEditComponent } from '../user-view-edit/user-view-edit.component';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  providers: [CustomTableService]
})
export class UsersListComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(CustomTableComponent) cTable!: CustomTableComponent;
  @ViewChild(CustomTableFilterComponent) cFilter!: CustomTableFilterComponent;
  @Input() searchtext: string = '';
  @Input() approvelist: boolean = true;
  columnSearchOptionsList: any = [];
  totalRecords = 0;
  zoneAllOptions: any = [];
  districtAllOptions: any = [];
  regionOptions: any = [];
  zoneOptions: any = [];
  districtOptions: any = [];
  departmentOptions: any = [];
  groupsOptions: any = [];
  entityOptions: any = [];
  permission: any = PagePermssions
  actionPermissions = {
    view: [this.permission.VIEW_CUSTOMUSER],
    edit: [this.permission.CHANGE_CUSTOMUSER],
    delete: [this.permission.DELETE_CUSTOMUSER]
  };
  filterParamValues: any;
  tableFields: any;
  staticText: any = (textConfiguration as any).default;
  columnDefs: any = this.staticText?.user_management?.entity?.list?.table_fields;
  startRow: number = this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.startRow;
  endRow: number = this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.endRow;
  usersList: any = [];
  filterSource: any = this.staticText?.user_management?.user?.list?.table_top_filter;
  statusData: any = [
    {
      id: 1,
      name: "Active",
      key: "true",
    },
    {
      id: 2,
      name: "Inactive",
      key: "false"
    }
  ]
  filterKeys: any = [
    { key: "user_type", source: [], sourceKey: 'id', display_key: 'display_name' },
    { key: "groups", source: [], sourceKey: 'id', display_key: 'display_name' },
    { key: "is_active", source: this.statusData, sourceKey: 'key' },
    { key: "date_range" }
  ];
  tableHeader: any = {
    multi: false,
    model: false,
    multiSelect: false
  };
  userDetails: any;
  regionListSub$: any;
  mzoListSub$: any;
  districtListSub$: any;
  searchForm: FormGroup | any;

  ordering: any = [
    {
      id: 1,
      name: 'System User',
      active: true,

    },
    {
      id: 2,
      name: 'Private Valuer List',
      active: false,

    },

  ];
  selectedOrdering = this.ordering[0];
  pageMode: any;
  constructor(
    private activated_route: ActivatedRoute,
    private store: Store<{ master: AppState }>,
    private state: State<AppState>,
    private _http: HttpService,
    public _common: CommonService,
    private _lodash: LodashService,
    public _routeService: RouterService,
    private _loader: LoaderService,
    private _modal: ModalService,
    private http: HttpClient,
    private _snackBar: SnackbarService,
    protected breadcrumbService: BreadCrumbService,
    private _master: MasterService,
    private datePipe: DatePipe,
    private _storage: StorageService,
    private _tableSerivce: CustomTableService,
    private formbuilder: FormBuilder,
  ) {
    this.tableFields = this.staticText?.user_management?.user?.list?.table_fields;
    this.setBreadCrumb();
    this.initiateMasterReducerCall();
  }

  initiateMasterReducerCall() {
    this.initiateRegionListSub();
    this.initiateMzoListSub();
    this.initiateDistrictListSub();
  }

  getRouteParams() {
    const routeInfo: any = this.activated_route?.data;
    this.pageMode = routeInfo?.value?.mode;
    this._common.userMangementTab?.forEach((element: any) => {
      element.active = false;
    });
    this._common.userMangementTab.find((x: any) => x.mode == this.pageMode).active = true;
  }

  tabClick(menu: any) {
    this._common.navigatePages(menu.route)
  }

  previewDialog(type: string, title: string, data?: any) {
    this._modal
      .openCommonDialog({
        component: UserViewEditComponent,
        width: "1000px",
        data: {
          type: type,
          title: title,
          data: data,
          id: data?.id
        }
      })
      .afterClosed()
      .subscribe((result: any) => {
        console.log('result', result);

        if (result) {
          this.getUserList()
        }
      });
  }

  addUser() {
    // this._routeService.navigatePages(`user-management/users/add`)
    this.previewDialog('add', "Add New User Information", null);
  }

  // TODO: Need to remove this function 
  deleteRegion() {
    this.store.dispatch(removeDataFromMaster({ 'data': { 'key': 'region', 'deleteId': 8 } }));
  }

  // TODO: Need to remove this function 
  updateRegion() {
    let updatedData = {
      "id": 18,
      "is_active": true,
      "created_on": "2024-01-04T13:00:36.004972Z",
      "last_updated_on": "2024-01-04T13:00:36.004992Z",
      "name": "New Central-1",
      "display_name": "New Central-1",
      "created_by": null,
      "last_updated_by": null
    }
    this.store.dispatch(updateDataToMaster({ 'data': { 'key': 'region', 'updateId': 18, 'updatedData': updatedData } }));
  }

  // TODO: Need to remove this function 
  addNewRegion() {
    let newData = {
      "id": 18,
      "is_active": true,
      "created_on": "2024-01-04T13:00:36.004972Z",
      "last_updated_on": "2024-01-04T13:00:36.004992Z",
      "name": "New Central",
      "display_name": "New Central",
      "created_by": null,
      "last_updated_by": null
    }
    this.store.dispatch(addDataToMaster({ 'data': { 'key': 'region', 'newData': newData } }));
  }

  initiateRegionListSub() {
    this.regionListSub$ = this.store.select(getRegionList).subscribe((data: any) => {
      console.log('initiateMasterReducerCall initiateRegionListSub: ', data)
      if (data.length) {
        this.regionOptions = data || [];
        this.setFilterOptions(3, this.regionOptions);
      }
    })
  }

  initiateMzoListSub() {
    this.mzoListSub$ = this.store.select(getMzoList).subscribe((data: any) => {
      console.log('initiateMasterReducerCall getMzoList: ', data)
      if (data.length) {
        this.zoneAllOptions = data || [];
        // this.setFilterOptions(3, this.regionOptions);
      }
    })
  }

  initiateDistrictListSub() {
    this.districtListSub$ = this.store.select(getDistrictList).subscribe((data: any) => {
      console.log('initiateMasterReducerCall getDistrictList: ', data)
      if (data.length) {
        this.districtAllOptions = data || [];
        // this.setFilterOptions(4, this.districtAllOptions);
      }
    })
  }

  setBreadCrumb() {
    //console.log('user list setBreadCrumb: ');

    let breadcrumbs: any = [
      {
        "label": "User",
        "url": "users"
      }
    ]
    this.breadcrumbService.setCustomBreadCrumb(breadcrumbs);
    this.breadcrumbService.setCustomBreadCrumbAction({});
    this.breadcrumbService.breadCrumbView(false);
  }

  ngOnInit(): void {
    this.getRouteParams();

    this.updateCustomTableConfig();
    this.initialDependencies();
    this.getUserDetailsId();
  }

  getUserDetailsId() {
    this.userDetails = this._storage.getUserDetails();
  }

  ngOnChanges(changes: SimpleChanges | any) {
    // if (!changes.searchtext.firstChange) {
    //   this.searchKeyword(this.searchtext);
    // }
  }

  searchKeyword(data: any) {
    // this.searchForm?.get('keyword')?.valueChanges.subscribe((data: any) => {
    this.resetPagination();
    let param: any = { ...this.filterParamValues, ...{ search: this.searchtext } };
    this.getUserList(this._common.filterParamsConstructor(param, this.startRow, this.endRow));
    // })
  }

  keyWordSearchFilter(ev: any) {
    this.resetPagination();
    let param: any = { ...this.filterParamValues, ...{ search: ev }, ...{ column_search: this.getColumnSearch() } };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getUserList(result);
  }

  getColumnSearch(): any {
    return this.cTable?.searchObj;

    // let searchObj:any = this.cTable?.searchObj || {};
    // let searchObj:any = {};
    // let params = new URLSearchParams(searchObj);
    // return params.toString()
  }

  getSearchFormVal() {
    return (this.searchForm && this.searchForm.get('keyword').value) || '';
  }

  loadUserList() {
    this.keyWordSearchFilter(this.getSearchFormVal());
  }


  initialDependencies() {
    this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
    if (!this.searchtext) {
      this.loadUserList();
    } else
      this.getUserList();
    this.getRoleList();
    this.getEntityList();
    this.initiateForm()
  }

  initiateForm() {
    this.searchForm = this.formbuilder.group({
      keyword: ['']
    })
  }

  setFilterOptions(keyIndex: any, options: any) {
    console.log('setFilterOptions: ', keyIndex, options)
    this.filterKeys[keyIndex]['source'] = options;
    this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
  }

  getRoleList(idString: string = '-1') {
    let endpoint: any = Endpoints.GET_DROPDOWN_ROLES;
    if (idString != '-1') {
      endpoint += '?user_type=' + idString;
    }
    this._http.requestCall(endpoint, ApiMethod.GET).subscribe((response: any) => {
      this.groupsOptions = response?.data || [];
      this.filterKeys[1]['source'] = this.groupsOptions;
      this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
    });
  }

  getEntityList() {
    let endpoint: any = Endpoints.GET_DROPDOWN_ENTITY;
    this._http.requestCall(endpoint, ApiMethod.GET).subscribe((response: any) => {
      this.entityOptions = response?.data || [];
      this.filterKeys[0]['source'] = this.entityOptions;
      this._common?.filterSourceConstructor(this.filterSource, this.filterKeys);
    });
  }

  updateCustomTableConfig() {
    this.tableFields['is_active'] = {
      ...this.tableFields['is_active'],
      "dropdown_config": {
        "options": [{
          "key": "ACTIVE",
          "value": true
        }, {
          "key": "INACTIVE",
          "value": false
        }],
        "className": "active-inactive-dropdown",

      }
    }
    //console.log('updateCustomTableConfig: ', this.tableFields);
  }

  resetList(list: any) {
    this.usersList = list;
    this.cTable.refreshTableData(list, false, this.startRow);
  }

  // onChangeActiveDropdown(selectedVal: any, fieldName: any, data: any) {
  //   //console.log('onChangeActiveDropdown: ', data,fieldName, selectedVal, this.usersList);
  //   let clonedRoleList = this._lodash.Clonedata(this.usersList);
  //   let matchedIndex = this._lodash.findIndexBy(this.usersList, 'id', data.id);
  //   if(matchedIndex > -1) {
  //     this.usersList[matchedIndex][fieldName] = selectedVal == 'True' ||  selectedVal == 'true' || selectedVal == true ? true : false;
  //     //console.log('onchangedrop111: ', matchedIndex, this.usersList,  this.usersList[matchedIndex][fieldName])
  //     if(data.id == this.userDetails.id) {
  //       this._snackBar.loadSnackBar(FailedMessage.PERMISSION_FAILED_MSG, colorCodes.WARNING);
  //       this.resetList(clonedRoleList);
  //     } else {
  //       this.updateStatus(this.usersList[matchedIndex][fieldName], data.id, clonedRoleList);
  //     }
  //   }
  //   //console.log('onchangedrop: ', matchedIndex, this.usersList)
  // }

  // updateStatus(newStatus: boolean, userId:any, clonedRoleList:any) {
  //   this._modal
  //   .openWarningDialog({
  //     data: {
  //       paragraph: `<p class="fs-14">Selected roles are mapped to users and will be no longer active.</p>`,
  //     },
  //   }).afterClosed().subscribe(result => {
  //     if (result) {
  //       this._loader.show();
  //       let endpoint: any = Endpoints.GET_USER_LISTS + userId + '/';
  //       let payload = {
  //         is_active: userId.is_active
  //       }
  //       this._http.requestCall(endpoint, ApiMethod.PATCH, payload).subscribe((response: any) => {
  //         this.resetList(this.usersList);
  //         this._loader.hide();
  //       }, (error: any) => {
  //         this.resetList(clonedRoleList);
  //         this._loader.hide();
  //       });
  //     } else {
  //       this.resetList(clonedRoleList);
  //       console.log(clonedRoleList);

  //     }
  //   })
  // } 

  resetPagination() {
    this._tableSerivce.currentPage = 1;
    this.startRow = this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.startRow;
    this.endRow = this._tableSerivce.pageSize || this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.endRow;
  }

  filterParams(data?: any) {
    this.resetPagination();
    this.filterParamValues = data?.value;
    let param: any = { ...this.filterParamValues, ...this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getUserList(result);
    if (data?.value == null) {
      this.getRoleList();
      this.getEntityList();
    }
  }

  pageChange(evt: any) {
    this.startRow = evt?.startValue;
    this.endRow = evt?.endValue;
    console.log('pageChange: ', evt, this.startRow, this.endRow, this.filterParamValues)
    let param: any = { ...this.filterParamValues, ...this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow,
    );
    this.getUserList(result)
  }

  sortColumn(data: any) {
    let param: any = { ...this.filterParamValues, ...data, ...this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getUserList(result, true)
  }

  getDisplayNameForArrList(data: any = [], fieldName: string) {
    let displayName = '';
    let recordsData = this._lodash.getData(data, fieldName, []);
    displayName = this._lodash.getData((this._lodash.mapData(recordsData, 'display_name') || []), '0', '');
    displayName += recordsData.length > 1 ? ' +' + (recordsData.length - 1) : '';
    return displayName;
  }

  getUserList(paramValues: string = '', sorting: boolean = false, url: string = '', data_name: string = '') {
    this._loader.show();
    // let endpoint:string = Endpoints.GET_USER_LISTS;
    let endpoint: any = '';
    if (this.approvelist) {
      endpoint = Endpoints.GET_USER_LISTS + paramValues;
    } else {
      endpoint = Endpoints.GET_PRIVATE_LIST
    }
    // endpoint = endpoint + paramValues;
    //console.log('getUserList paramValues: ', paramValues)
    // let endpoint = Endpoints.GET_USER_LISTS + paramValues;
    this._http.requestCall(endpoint, ApiMethod.GET).subscribe((apiResponse: any) => {
      // this._http.thirdPartyRequestCall(BaseURL.KEYCLOAK_API_URL, endpoint, ApiMethod.GET).subscribe((response: any) => {
      //console.log('GetUserList: ', response)
      // this._http.requestCall(endpoint, ApiMethod.GET).subscribe((response: any) => {
      //`<span class="" data-toggle="tooltip" data-placement="top" title="${list[i]["name"]}">${list[i]["name"]}</span>`
      //data.username
      let response = apiResponse.data || {};
      this._loader.hide();
      this.totalRecords = response?.total_records;
      this.usersList = response?.records?.map((data: any) => ({
        "id": data?.id,
        "fullname": `${data.is_complete_profile ? '<img title="Completed profile" src="/assets/images/common/icons/complete.svg" alt="Completed">' : '<img src="/assets/images/common/icons/pending.svg"  title="InCompleted profile" alt="Pending" style="margin-left:0.5rem;">'} <span  style= "overflow: hidden !important; text-overflow: ellipsis !important; " data-toggle="tooltip" data-placement="top">${data.first_name + " " + data.last_name}</span>`,
        "last_name": data.last_name,
        "first_name": data.first_name,
        "is_active": data.is_active,
        "username": data?.username,
        "email": data.email,
        "user_type": data?.user_type?.display_name || "",
        "groups": this._lodash.getData(data, 'groups.0.display_name', ''),
        "regionTitle": this._lodash.mapData(this._lodash.getData(data, 'region', []), 'display_name') || '',
        "region": this.getDisplayNameForArrList(data, 'region'),
        "zone": this.getDisplayNameForArrList(data, 'zone'),
        "zoneTitle": this._lodash.mapData(this._lodash.getData(data, 'zone', []), 'display_name').toString() || '',
        "district": this.getDisplayNameForArrList(data, 'district'),
        "districtTitle": this._lodash.mapData(this._lodash.getData(data, 'district', []), 'display_name').toString() || '',
        "created_by": data?.created_by || '-',
        "created_on": this.datePipe?.transform(data.created_on, FORMAT.DATE_TIME) ?? '-',
        "last_updated_on": this.datePipe?.transform(data.last_updated_on, FORMAT.DATE_TIME) ?? '-',
        "last_updated_by": data?.last_updated_by || '-',
        "createdTimestamp": this.datePipe?.transform(data.created_on) ?? '--',
      }))
      this.cTable.setTableTotalRecordsCnt(this.totalRecords);
      this.cTable.refreshTableData(this.usersList, sorting, this.startRow);

      console.log('total', this.totalRecords);


      this._common.mapSerialNumberAndRoutePath((this['startRow'] || 0),
        this.usersList,
        [
          {
            status: true,
            route: '/users/view/',
            routeStatus: false,
            tooltip: 'view'
          },
          {
            status: true,
            mode: 'view',
            route: 'edit/',
            tooltip: 'view',
            routeStatus: false,
            icon: 'view_action',
          },
          { status: this.approvelist ? true : false, route: 'edit', mode: 'edit', routeStatus: false, tooltip: 'edit', modalStatus: true },
        ],
        false
      )
      //console.log('GetUserList usersList: ', this.usersList)
    });
  }



  onEditAction(ev: any) {
    if (ev?.action?.mode == 'view' || ev?.action?.icon == 'view_action') {
      //  this._routeService.navigatePages('/user-management/users/view/'+ev.Data.id + '/');
      this.previewDialog('view', "View User Information", ev.Data);
    }
    else {
      this.previewDialog('edit', "Edit User Information", ev.Data);
      //  this._routeService.navigatePages('/user-management/users/edit/'+ev.Data.id + '/');
    }
  }

  onCustomAction(actionData: any) {
    //console.log('onCustomAction', actionData);
    this._routeService.navigatePages('/user-management/users/view/' + actionData['Data']['id']);
  }

  deleteData(id: any) {
    this._modal.openDeleteDialog({ data: { paragraph: `<p class="fs-14">Do you want to delete this item <span class="color-primary">"${this.usersList.find((x: { id: any; }) => x.id == id).username}"</span></p>` } }).afterClosed().subscribe(result => {
      if (result) {
        // this._loader.show();
        // let params: any = Endpoints.KEYCLOAK_USER_API + id;
        // this._http.thirdPartyRequestCall(BaseURL.KEYCLOAK_API_URL, params, ApiMethod.DELETE).subscribe((response: any) => {
        //   if (!response) {
        //     this._loader.hide();
        //     this._modal.openSuccessDialog({ data: { paragraph: SuccessMessage.SUBMITTED } });
        //     this.getUserList();
        //   } else {
        //     this._loader.hide();
        //   }
        // }, (error: any) => {
        //   this._loader.hide();
        // });
      }
    })
  }

  getIdStringFromArr(inputArr = [], fieldName = 'id') {
    return (this._lodash.mapData(inputArr, fieldName) || []).toString()
  }

  dependencyFilter(data?: any) {
    console.log('dependencyFilter:: ', data)
    if (!data?.isFilter) {
      return;
    }
    if (this.filterKeys?.[data?.index]?.key == "user_type") {
      this.cFilter.customTableFilterFormGroup?.get('groups').reset();
      const ids = data?.currentDate?.map((x: any) => x.id).toString()
      this.getRoleList(ids)
    }
    if (this.filterKeys?.[data?.index]?.key == "region") {
      if (data?.currentDate.length) {
        return;
      } else {
        console.log('dependencyFilter else:: ', this.regionOptions, this.zoneAllOptions, this.districtAllOptions)
        this.setFilterOptions(3, this.regionOptions);
        this.setFilterOptions(5, this.zoneAllOptions);
        this.setFilterOptions(4, this.districtAllOptions);
      }
      //   const params = {
      //     "level": "sub_category",
      //     "parent": data?.currentDate?.id || ''
      //   };
      //   let result: any = this._common.filterParamsConstructor(params)
      //   this.CreateRfxService$.getMappedSubCategoryList(result).subscribe((response: any) => {
      //     if (response?.records?.length > 0) {
      //       this._loader.hide();
      //       this.filterKeys[4]['source'] = response.records;
      //       this._common?.filterSourceConstructor(
      //         this.filterSource,
      //         this.filterKeys
      //       );
      //     }
      //     else {
      //       // this._snackBar.loadSnackBar(FailedMessage.SUB_CAT_NOT_FOUND, colorCodes.WARNING);
      //       this.filterKeys[4]['source'] = [];
      //       this._common?.filterSourceConstructor(
      //         this.filterSource,
      //         this.filterKeys
      //       );
      //     }
      //   })
    } else if (this.filterKeys?.[data?.index]?.key == "zone") {
      if (data?.currentDate.length) {
        return;
      } else {
        this.setFilterOptions(4, this.zoneAllOptions);
        this.setFilterOptions(5, this.districtAllOptions);
      }
      // const params = {
      //   "level": "product_category",
      //   "parent": data?.currentDate?.id || ''
      // }
      // let result: any = this._common.filterParamsConstructor(params);
      // this.CreateRfxService$.getMappedProductCategoryList(result).subscribe((response: any) => {
      //   if (response?.records?.length > 0) {
      //     this._loader.hide();
      //     this.filterKeys[5]['source'] = response.records;
      //     this._common?.filterSourceConstructor(
      //       this.filterSource,
      //       this.filterKeys
      //     );
      //   }
      //   else {
      //     this._snackBar.loadSnackBar(FailedMessage.PRODUCT_CAT_NOT_FOUND, colorCodes.WARNING);
      //     this._loader.hide();
      //     this.filterKeys[5]['source'] = [];
      //     this._common?.filterSourceConstructor(
      //       this.filterSource,
      //       this.filterKeys
      //     );
      //   }
      // })
      // } else if (this.filterKeys?.[data?.index]?.key == "district") {
    }
  }

  /**
   * @description
   * Table column wise search emitter...............
   * @param ev 
   */
  columnSearch(ev: any) {
    this.resetPagination();
    let param: any = { ...this.filterParamValues, ... this.getColumnSearch() };
    let result: any = this._common.filterParamsConstructor(
      param,
      this.startRow,
      this.endRow
    );
    this.getUserList(result);
  }

  //...............Table column wise search emitter



  ngOnDestroy() {
    console.log('UserListComponent destroy')
    this.regionListSub$?.unsubscribe();
    this.mzoListSub$?.unsubscribe();
    this.districtListSub$?.unsubscribe();
  }
}




