import { AfterViewInit, Component, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { BreadCrumbService } from 'src/app/shared/services/bread-crumb/bread-crumb.service';
import { EntityListComponent } from './entity/entity-list/entity-list.component';
import { RouterService } from 'src/app/core/services/router/router.service';
import { ActivatedRoute } from '@angular/router';
import { LodashService } from 'src/app/core/services/lodash/lodash.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as textConfiguration from 'src/assets/branding/text-branding/static-text-configuration.json';
import { CommonService } from 'src/app/core/services/common/common.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PagePermssions } from 'src/app/core/services/utils/constants';
import { StorageService } from 'src/app/core/services/storage/storage.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserManagementComponent implements AfterViewInit{
  permission: any = PagePermssions
  @ViewChildren(EntityListComponent) entityList!:QueryList<EntityListComponent>;

  selectedTabIndex: any = 2;
  searchKeyword:string='';
  approveList :boolean = false;
  searchForm: FormGroup | any;
  staticText: any = (textConfiguration as any).default;
  filterParamValues: any;
  startRow: number = this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.startRow;
  endRow: number = this.staticText?.common?.custom_table_properties?.custom_table_pagination_block?.custom_table_page_size?.endRow;
  componentEnable:boolean = false;
  constructor(
    protected breadcrumbService: BreadCrumbService,
    private _lodash: LodashService,
    private activated_route: ActivatedRoute,
    public _routeService: RouterService,
    private formbuilder: FormBuilder,
    private _common: CommonService,
    private _storage: StorageService,
  ) {
    console.log('UserManagementComponent constructor')
  }
  
  
  ngOnInit(): void {
    // this._common.getSetRouteValue(this.activated_route?.data);
    this.initiateForm();
    //this.setBreadCrumb();
    this.searchList();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.selectedTabIndex = this.activated_route.snapshot.paramMap.get('id') ?? 0;
      this.componentEnable = true;
      this.setBreadCrumb();
    }, 0);
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  initiateForm() {
    this.searchForm = this.formbuilder.group({
      keyword: ['']
    })
  }

  setBreadCrumb() {
    let breadcrumbActionBtn: any = {};
    let breadcrumbs = [
      {
        "label": "User Management",
        "url": `users/list`
      }
    ]
    if (this.selectedTabIndex == 0) {
      breadcrumbs.push({
        "label": "Users",
        "url": `users/list`
      })
      breadcrumbActionBtn = {
        label: "Add New User",
        onClickActionFn: this.onClickFn.bind(this),
        icon: "plus",
        permission: [this.permission.ADD_CUSTOMUSER]
      }
    } else if (this.selectedTabIndex == 1) {
      breadcrumbs.push({
        "label": "Roles",
        "url": `users/list/1`
      })
      breadcrumbActionBtn = {
        label: "Add Role",
        onClickActionFn: this.onClickFn.bind(this),
        icon: "plus",
        permission: [this.permission.ADD_CUSTOMGROUP]
      }
    } else if (this.selectedTabIndex == 2) {
      breadcrumbActionBtn = {
        label: "Add User Group",
        onClickActionFn: this.onClickFn.bind(this),
        icon: "plus",
        permission: [this.permission.ADD_ENTITY]
      }
      breadcrumbs.push({
        "label": "User Group",
        "url": `users/list`
      })
    }
    this.breadcrumbService.setCustomBreadCrumb(breadcrumbs);
    this.breadcrumbService.setCustomBreadCrumbAction(breadcrumbActionBtn);
  }

  onTabChanged(e: any) {
    //console.log('onTabChanged e:', e, this.selectedTabIndex)
    this.setBreadCrumb();
  }

  onClickFn() {
    //console.log('onclickfn:::', this.entityList, this, this.selectedTabIndex, this.entityList.get(0))
    if(this.selectedTabIndex == 2 && this.entityList.get(0)) {
      this.entityList.get(0)?.addEntity();
    } else if(this.selectedTabIndex == 1) {
      this._routeService.navigatePages(`users/role/add`)
    } else {
      this._routeService.navigatePages(`users/add`)
    }
  }

  searchList() {
    this.searchForm?.get('keyword')?.valueChanges.subscribe((data: any) => {
      this.filterParamValues = { search_text: data };
      this.searchKeyword = data;
    })
  }
}




